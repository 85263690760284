<template>
  <div class="">
    <!-- 菜单导航 -->
    <el-Menu></el-Menu>
  </div>
</template>

<script>
import ElMenu from "@components/Menu";
export default {
  name: "liveList",
  components: {
    ElMenu
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
